<template>
  <CommonDialog v-model="showDialog" popper-class="html-dialog" :dialog-style="dialogStyle">
    <template #close>
      <Close class="close" />
    </template>
    <div class="content" v-html="html" />
  </CommonDialog>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue"
import Close from "assets/images/pages/newHome/common-close.svg"

const props = defineProps({
  html: {
    type: String,
    default() {
      return ""
    }
  }
})

const dialogStyle = reactive<CSSProperties>({})

const showDialog = ref(true)
</script>

<style lang="scss" scoped>
.html-dialog {
  .content {
    width: 1320px;
    aspect-ratio: 1428 / 802;
    display: flex;
    :deep(iframe) {
      width: 100%;
      height: 100%;
    }
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    transform: translate(0, calc(-100% - 8px));
    cursor: pointer;
    color: rgba(118, 118, 118, 1);
    transition: all 0.3s;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

@media only screen and (max-width: 1368px) {
  .html-dialog {
    .content {
      width: calc(100vw - 48px);
    }
  }
}
</style>

<style lang="scss">
.html-dialog {
  .share_cover {
    background: rgba(0, 0, 0, 0.8);
  }
}
</style>
